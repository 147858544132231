import React, { Component } from 'react';
import { Button, Container, Modal, ModalHeader, ModalFooter, ModalBody, ButtonGroup, ButtonToolbar} from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th, Tfoot } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { withRouter } from 'react-router-dom';
import ArtikelEntry from './ArtikelEntry';
import AppNavbar from './AppNavbar';
import AppNavbarWithoutCart from './AppNavbarWithoutCart';

class Preisliste extends React.Component {

  constructor(props) {
    super(props);
    this.state = {addressEnabled: false, preisliste: [], total: 0, quantity: 0, bestellartikelArray: [], settingsData: [], isLoading: true, showDialog: false, payPalPaymentEnabled: false, payPalPayment: false, disabledOrderButton: false, bringOrder: false, abholzeit: "8:00", comment: "", vorname: "", nachname: "", mail: "", phone: "", strasse: "", hausnummer: "", plz: "", ort: "", errorMessage: ""};
    //this.remove = this.remove.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.increaseQuantity = this.increaseQuantity.bind(this);
    this.decreaseQuantity = this.decreaseQuantity.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBringOrderClick = this.handleBringOrderClick.bind(this);
    this.doClassicOrder = this.doClassicOrder.bind(this);
  }

  componentDidMount() {
    this.setState({isLoading: true});

        // get server settings
        fetch('https://manne.org:8080/settings', {
          method: 'GET',
          headers: {
            'Authorization': 'MVMFrontend123'
          },
          }).then(async response => {
            const settingsData = await response.json();
    
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (settingsData && settingsData.message) || response.status;
                return Promise.reject(error);
            }
            // request successful
            this.setState({payPalPaymentEnabled: settingsData.payPalPaymentEnabled, settingsData: settingsData});

        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    

    fetch('https://manne.org:8080/preisliste')
    .then(async response => {
      if (response.status == "403") {
        this.setState({isLoading: false});
        this.setState({preisliste: null});
      } else {
        const data = await response.json();
        console.log(data);
        this.setState({preisliste: data, isLoading: false});
        // check for error response
        if (!response.ok) {
            // get error message from body or default to response status
            const error = response.status;
            return Promise.reject(error);
        }
      }
  })
  .catch(console.error);
  }


  addToCart(artikelId, comment) {

    var cartObject = {};

    cartObject['id'] = artikelId;
    cartObject['quantityOrder'] = 1;

    var bestellartikelArrayTmp = this.state.bestellartikelArray;
    bestellartikelArrayTmp.push(cartObject);
    this.setState({bestellartikelArray: bestellartikelArrayTmp});
  }

  countEintrittskarten() {
    var bestellartikelArrayTmp = this.state.bestellartikelArray;
    var numberOfEintrittskarten = 0;

    for (var i = 0; i < bestellartikelArrayTmp.length; i++) {

      // get type for artikel with given ID
      var artikelIndex = this.state.preisliste.findIndex(x => x.id === bestellartikelArrayTmp[i].id);
      var artikelType = this.state.preisliste[artikelIndex].artikelType;
      
      numberOfEintrittskarten += bestellartikelArrayTmp[i].quantityOrder;
    }
    return numberOfEintrittskarten;
  }

  determineTotalAndQuantity() {
    var total = 0;
    var quantity = 0;

    for (var i = 0; i < this.state.bestellartikelArray.length; i++) {
      
      // get price for artikel with given ID
      var artikelIndex = this.state.preisliste.findIndex(x => x.id === this.state.bestellartikelArray[i].id);
      var artikelPrice = this.state.preisliste[artikelIndex].price;
            
      total += artikelPrice * this.state.bestellartikelArray[i].quantityOrder;
      quantity += this.state.bestellartikelArray[i].quantityOrder;
    }

    this.setState({total: total, quantity: quantity}); 
    return total;
  }

  increaseQuantity(artikelId) { 

    var bestellartikelArrayTmp = this.state.bestellartikelArray;
    var index = bestellartikelArrayTmp.findIndex(x => x.id === artikelId);

   // Check if element exists
    if (index < 0) {
      
      // array does NOT contain element, new element has to be inserted
      var cartObject = {};
      cartObject['id'] = artikelId;
      cartObject['quantityOrder'] = 1;

      bestellartikelArrayTmp.push(cartObject);
    } else {

      // maximum number of items
      if (bestellartikelArrayTmp[index].quantityOrder >= 15) {
        return;
      }
      // array contains element, quantity has to be increased
      bestellartikelArrayTmp[index].quantityOrder += 1;
    }

    this.determineTotalAndQuantity();

    this.setState({bestellartikelArray: bestellartikelArrayTmp, errorMessage: ""}); 

  }

  decreaseQuantity(artikelId) {

    var bestellartikelArrayTmp = this.state.bestellartikelArray;
    var index = bestellartikelArrayTmp.findIndex(x => x.id === artikelId);

   // Check if element exists
    if (index >= 0) {

      // if quantity <= 1, element has to be removed completely
      if (bestellartikelArrayTmp[index].quantityOrder <= 1) {
        bestellartikelArrayTmp.splice(index, 1);
      } else {

        // if element > 1, quantity has to be decreased
        bestellartikelArrayTmp[index].quantityOrder -= 1;
      }

      this.determineTotalAndQuantity();

      this.setState({bestellartikelArray: bestellartikelArrayTmp, errorMessage: ""}); 
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      errorMessage: ""
    });

  }

  handleComment(event) {
    this.setState({comment: event.target.value, errorMessage: ""});
  }

  handleBringOrderClick() {
    //this.setState({bringOrder: true});

  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

  validatePhone(number) {
    if (number == null || number.trim().length <= 0) {
      return false;
    }
    if (number.match('[0-9][0-9][0-9]+')) {
      return true;
    }
    return false;
  }

  doFormValidityCheck(validateOnlyForDialog, validateForPayPal) {
   // Validate input
   if (this.state.bestellartikelArray == null || this.state.bestellartikelArray.length <= 0) {
    this.setState({errorMessage: "Bitte wählen Sie mindestens einen Artikel aus."});
    return false;
  }

  if (validateOnlyForDialog) {
    return true;
  }

  if (validateForPayPal) {

    if (this.validatePhone(this.state.phone) == false) {
      this.setState({errorMessage: "Bitte geben Sie eine gültige Telefonnummer an."});   
      return false;  
    }

        // do not validate name, address etc. since it is provided by PayPal

    return true;
  }

  if (this.state.vorname == null || this.state.vorname.trim().length <= 0) {
    this.setState({errorMessage: "Bitte geben Sie Ihren Vornamen an."});   
    return false;  
  }

  if (this.state.nachname == null || this.state.nachname.trim().length <= 0) {
    this.setState({errorMessage: "Bitte geben Sie Ihren Nachnamen an."});   
    return false;  
  }

  if (this.state.mail == null || this.state.mail.trim().length <= 0) {
    this.setState({errorMessage: "Bitte geben Sie eine E-Mailadresse an."});   
    return false;  
  }

  if (this.validateEmail(this.state.mail) == false) {
    this.setState({errorMessage: "Bitte geben Sie eine gültige E-Mailadresse an."});   
    return false;
  }

  if (this.validatePhone(this.state.phone) == false) {
    this.setState({errorMessage: "Bitte geben Sie eine gültige Telefonnummer an."});   
    return false;  
  }


    if (this.state.addressEnabled) {
      
    if (this.state.strasse == null || this.state.strasse.trim().length <= 0) {
      this.setState({errorMessage: "Bitte geben Sie eine Straße an."});   
      return false;  
    }  

    if (this.state.hausnummer == null || this.state.hausnummer.trim().length <= 0) {
      this.setState({errorMessage: "Bitte geben Sie eine Hausnummer an."});   
      return false;  
    }
    
    if (this.state.plz == null || this.state.plz.trim().length <= 0) {
      this.setState({errorMessage: "Bitte geben Sie eine Postleitzahl an."});   
      return false;  
    } 
    
    if (this.state.ort == null || this.state.ort.trim().length <= 0) {
      this.setState({errorMessage: "Bitte geben Sie einen Ort an."});   
      return false;  
    }

  }

  return true;
}



  createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
       var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
       return v.toString(16);
    });
 }


  doClassicOrder() {

    if (!this.state.disabledOrderButton) {
    
      if (this.doFormValidityCheck(false, false)) {

        this.setState({disabledOrderButton: true});

        var besteller = {};
        besteller['vorname'] = this.state.vorname.substring(0,250);
        besteller['nachname'] = this.state.nachname.substring(0,250);
        besteller['strasse'] = this.state.strasse.substring(0,250);
        besteller['hausnummer'] = this.state.hausnummer.substring(0,250);
        besteller['plz'] = this.state.plz.substring(0,250);
        besteller['ort'] = this.state.ort.substring(0,250);
        besteller['mail'] = this.state.mail.substring(0,250);
        besteller['phone'] = this.state.phone.substring(0,250);

        var cart = {};
        cart['comment'] = this.state.comment.substring(0,250);
        cart['besteller'] = besteller;
        cart['bestellartikel'] = this.state.bestellartikelArray;
        console.log(JSON.stringify(cart, null, 2));

        fetch('https://manne.org:8080/bestellung', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(cart),
        }).then(async response => {
          const data = await response.json();
          var orderSuccessful = false;

          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          orderSuccessful = true;
          this.setState({errorMessage: "Bestellung erfolgreich! " + data.id});
          this.props.history.push({
          pathname: '/bestellbestaetigung',
          state: {
            data, orderSuccessful
          }
        })
      })
      .catch(error => {
        this.setState({disabledOrderButton: false});
        this.setState({errorMessage: "Fehler: Bestellung konnte nicht durchgeführt werden! Bitte versuchen Sie es erneut."});
          console.error('There was an error!', error);
      });
      }
    }
  }

  doPayPalOrder() {
    
    if (!this.state.disabledOrderButton) {
    
      if (this.doFormValidityCheck(false, true)) {

        this.setState({disabledOrderButton: true});

        var besteller = {};
        besteller['vorname'] = this.state.vorname.substring(0,250);
        besteller['nachname'] = this.state.nachname.substring(0,250);
        besteller['strasse'] = this.state.strasse.substring(0,250);
        besteller['hausnummer'] = this.state.hausnummer.substring(0,250);
        besteller['plz'] = this.state.plz.substring(0,250);
        besteller['ort'] = this.state.ort.substring(0,250);
        besteller['mail'] = this.state.mail.substring(0,250);
        besteller['phone'] = this.state.phone.substring(0,250);

        var cart = {};
        cart['comment'] = this.state.comment.substring(0,250);
        cart['besteller'] = besteller;
        cart['bestellartikel'] = this.state.bestellartikelArray;

          // make POST call to server
          fetch('https://manne.org:8080/bestellung/paypal/request?guid=' + this.createUUID(), {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(cart),
          }).then(async response => {
            const data = await response.json();
            console.log(data);
            window.location.href = data.link;


          })
          .catch(function(err) {
              console.info(err);
          });

        }
      }

    }

  openDialog(payPalPayment) {
    if (this.doFormValidityCheck(true, true)) {
      this.setState({showDialog: true, payPalPayment: payPalPayment});
    }
  }


  render() {

    const {preisliste, isLoading} = this.state;

    const handleDialogCancel = () => {
        this.setState({showDialog: false, errorMessage: ""});
      }

      const handleDialogConfirm = () => {
        if ((!this.state.payPalPayment && this.doFormValidityCheck(false, false)) || (this.state.payPalPayment && this.doFormValidityCheck(false, true))) {
          this.setState({showDialog: false, errorMessage: ""});
          if (this.state.payPalPayment) {
            this.doPayPalOrder();
          } else {
            this.doClassicOrder();
          }
        }
      }
  

    if (isLoading) {
      return (
      <div>
      <AppNavbarWithoutCart/>
      <Container fluid>
      Daten werden geladen ...
      </Container>
      </div>
      );
    }

    // check for PayPal callback
    var paymentState = -1; // -1 = no value given (normal cart), 0 = failed, 1 = booked (vorgemerkt), 2 = done, 3 = payment already done
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    if (params.get('state') == "paymentfailed") {
      paymentState = 0;
    } else if (params.get('state') == "paymentpossible") {
      paymentState = 1;
    } else if (params.get('state') == "success") {
      paymentState = 2;
    } else if (params.get('state') == "paymentalreadydone") {
      paymentState = 3;
    }

    if (preisliste != null) {

      const artikelList = preisliste.map(artikel => {
        if (artikel.availability == "AVAILABLE") {
        //if (artikel.availability == "AVAILABLE" && artikel.artikelType === "EINTRITT") {

          return (
          <ArtikelEntry
            artikel={artikel}
            bestellartikelArray={this.state.bestellartikelArray}
            onAddToCartChange={this.addToCart}
            onIncreaseQuantity={this.increaseQuantity}
            onDecreaseQuantity={this.decreaseQuantity}
            />
          /*<tr key={artikel.id}>
            <td style={{whiteSpace: 'nowrap'}}>{artikelbezeichnung}</td>
            <td>  <input type="text" onChange={this.commentHandle(artikel.id)} /></td>
            <td>
              <ButtonGroup>
                <Button size="sm" color="primary" onClick={() => this.addToCart(artikel.id)}>Zum Warenkorb hinzufügen</Button>
              </ButtonGroup>

            </td>
          </tr>
          */
          ) }
      });

      return (
        <div>
          <AppNavbar total={this.state.total} quantity={this.state.quantity}/>
          <Container fluid>
          <Modal isOpen={this.state.showDialog} fade={false}
                       style={{display: "block"}}>
                    <ModalHeader>
                        Bestellung bestätigen
                    </ModalHeader>
                    <ModalBody>
                {!this.state.payPalPayment &&
                <div>
                <form>
                <p><b>Besteller:</b></p>
                <p>
                <label>
                Vorname:
                </label>
                <br></br>
                <input
                  name="vorname"
                  type="input"
                  maxLength="250"
                  onChange={this.handleInputChange}
                  value={this.state.vorname}/>
                </p>
                <p>
                <label>
                Nachname:
                </label>
                <br></br>
                <input
                  name="nachname"
                  type="input"
                  maxLength="250"
                  onChange={this.handleInputChange}
                  value={this.state.nachname}/>
                </p>
                <p>
                <label>
                E-Mail-Adresse
                <small> (für den Ticketversand)</small>
                :
                </label>
                <br></br>
                <input
                  name="mail"
                  type="input"
                  maxLength="250"
                  onChange={this.handleInputChange}
                  value={this.state.mail}/>
                  <p></p>
                </p>
                <p>
                <label>
                Telefonnummer:
                <small> (nur Zahlen)</small>
                :
                </label>
                <br></br>
                <input
                  name="phone"
                  type="input"
                  onChange={this.handleInputChange}
                  value={this.state.phone}/>
                </p>
                {this.state.addressEnabled &&
                <div>
                <div id="adresshtml">
                <p>
                <label>
                Straße:                
                </label>
                <br></br>
                <input
                  name="strasse"
                  type="input"
                  onChange={this.handleInputChange}
                  value={this.state.strasse}/>
                </p>
                <p>
                <label>
                Hausnummer:
                </label>
                <br></br>
                <input
                  name="hausnummer"
                  type="input"
                  onChange={this.handleInputChange}
                  value={this.state.hausnummer}/>
                </p>    
                <p>
                <label>
                Postleitzahl:
                </label>
                <br></br>
                <input
                  name="plz"
                  type="input"
                  onChange={this.handleInputChange}
                  value={this.state.plz}/>
                </p>    
                <p>
                <label>
                Ort:
                </label>
                <br></br>
                <input
                  name="ort"
                  type="input"
                  onChange={this.handleInputChange}
                  value={this.state.ort}/>
                </p> 
                </div>
                </div>
                }
                </form>
                </div>
                }
                {this.state.payPalPayment &&
                <form>
                <div>
                <p>
                <small>Alle anderen erforderlichen Informationen werden bereits von PayPal bereitgestellt.</small></p>
                
                <p>
                <label>
                Telefonnummer:
                <small> (nur Zahlen)</small>
                :
                </label>
                <br></br>
                <input
                  name="phone"
                  type="input"
                  onChange={this.handleInputChange}
                  value={this.state.phone}/>
                </p>
                </div>
                </form>
                }
                  <p style={{ color: 'blue' }}><b>Sie haben {this.countEintrittskarten()} Artikel bzw. Position(en) ausgewählt.</b> </p>
                  <h4 className="error" style={{ color: 'red' }}> { this.state.errorMessage } </h4>
                    </ModalBody>
                    <ModalFooter>
                      <div>
                        <Button color="danger" onClick={handleDialogCancel}>
                            Abbrechen
                        </Button>{' '}
                        <Button color="success" onClick={handleDialogConfirm}>{this.state.payPalPayment ? "Via PayPal bestellen " : "Verbindlich bestellen"}</Button>
                        <br></br>
                        <small>Bei Bestellung verzichte ich auf mein 14-tägiges Widerrufsrecht gemäß BGB § 312 b Abs. 3 Nr. 6</small>
                        </div>
                    </ModalFooter>
                </Modal>
            {paymentState == 0 &&
            <div class="alert alert-danger" role="alert">
              Die Bezahlung via PayPal wurde abgebrochen oder ist aus technischen Gründen fehlgeschlagen! Bitte versuchen Sie es erneut.
            </div>
            }
            <h3>Test-Webseite: BITTE NICHTS BESTELLEN!!! SG Mettenberg e.V. Ticketverkauf Print@Home</h3>
            {true &&
            <div>
            <br></br>
            <img src={require('./SaubachkomedeLogo2.png')} width="95" height="100" id="logos"/>
            <img src={require('./SGMLogo.jpg')} width="83" height="98" id="logos"/>
            <p>Offizieller Ticketverkauf für das Jubiläumswochenende der SG Mettenberg. Sie können hier Ihr Ticket für den Aufritt der SAUBACHKOME.DE am Freitag, das schwäbische Buffet am Sonntag oder direkt beides bestellen.</p>
            <br></br>
            <b>SAUBACHKOME.DE</b>
            <ul>
              <li>Eintritt für den Auftritt der <a href="https://www.saubachkome.de/" target="_blank">SAUBACHKOME.DE</a> am <b>Freitag, 24. Januar 2025</b>, um 20:00 Uhr</li>
              <li>Mehrzweckhalle Mettenberg. Einlass ab 18:30 Uhr. Freie Platzwahl!</li>
            </ul>
            <b>Buffet am Sonntag</b>
            <ul>
              <li>Mittagessen mit schwäbischem Buffet am <b>Sonntag, 26. Januar 2025</b>, um TODO Uhr</li>
              <li>Mehrzweckhalle Mettenberg. Einlass ab TODO Uhr. Freie Platzwahl!</li>
            </ul>     
            <br></br> 
            <p>Mehr Infos zur Veranstaltung finden Sie hier: <a href="https://www.sg-mettenberg.de/" target="_blank">https://www.sg-mettenberg.de/</a> </p>
            <br></br>
            </div>
            }
            <br></br>   
            <div>
            <Table className="mt-4" style={{'border-width': '0px', 'border-style':'solid', 'border-color':'black'}}>
              <Thead>
              <Tr>
                <Th width="30%">Name</Th>
                <Th width="20%">Preis</Th>
                <Th width="25%">Menge</Th>
                <Th width="25%">Summe</Th>
              </Tr>
              </Thead>
              <Tbody>
              {artikelList}
              </Tbody>
            </Table>
            </div>
            <form>
              {/* Currently not needed
              <p>
                <label>
                Bestellung bringen 
                </label>
                <input
                  name="bringOrder"
                  type="checkbox"
                  checked={this.state.bringOrder}
                  onChange={this.handleInputChange}
                  onClick={this.handleBringOrderClick} />
                </p>
                */}
                <p></p>
                <p>
                  <label>
                  Kommentar:
                  </label>
                  <br></br>
                  <textarea type="text" maxLength="250" rows="4" cols="20%" value={this.state.comment} onChange={this.handleComment} />
              </p>
                <h4 className="error" style={{ color: 'red' }}> { this.state.errorMessage } </h4>
                 {new Date(this.state.settingsData.bankTransferOrderDeadline) > new Date() &&
                 <div>
                <ButtonToolbar aria-label="Toolbar with button groups">
                  <ButtonGroup className="mr-2" style={{'margin-top': '20px'}}>
                <Button size="lg" id="orderbutton" color="success" disabled={this.state.disabledOrderButton} onClick={() => this.openDialog(false, false)}>{this.state.disabledOrderButton && !this.state.payPalPayment ? 'Bestellung wird ausgeführt ...' : 'Jetzt bestellen'}</Button>
                </ButtonGroup>
                  </ButtonToolbar>
                  <small style={{'font-style': 'italic'}}>Sie erhalten eine Mail mit den Zahlungsinformationen und bezahlen per Überweisung. Nach Eingang und Prüfung Ihrer Überweisung bekommen Sie die Tickets per Mail zugesendet. Bearbeitungsdauer: ca. 3 Werktage.</small> 
                <p></p>
                </div>
                }
                {this.state.payPalPaymentEnabled && new Date(this.state.settingsData.paypalOrderDeadline) > new Date() &&
                <div>
                <ButtonToolbar aria-label="Toolbar with button groups">
                  <ButtonGroup className="mr-2" style={{'margin-top': '20px'}}>
                    <Button size="l" style={{'padding': '0px'}} id="orderbutton" disabled={this.state.disabledOrderButton} onClick={() => this.openDialog(true, false)}>
                      <img src="checkout-logo-large-de.png" />
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
                  <small style={{'font-style': 'italic'}}>Sie bezahlen per PayPal und erhalten Ihre Tickets innerhalb von wenigen Minuten per Mail an Ihre bei PayPal hinterlegte E-Mailadresse.</small> 
                  </div>
                  }
            </form>
          <div id="bottomelement">
            <br></br>
            <br></br>
            <h4>Wie funktioniert's?</h4>
            <p>Sie erhalten Ihre Tickets per E-Mail und können Sie bequem zu Hause ausdrucken oder auf Ihrem Smartphone vorzeigen (bitte vorher ausprobieren). Bestellungen über diese Webseite sind per Überweisung bis zum {new Date(this.state.settingsData.bankTransferOrderDeadline).toLocaleDateString("de-DE")} und per PayPal bis zum {new Date(this.state.settingsData.paypalOrderDeadline).toLocaleDateString("de-DE")} Tagesende möglich.</p>
            <p>Sollten Sie nach der Bestellung keine E-Mail erhalten haben, so prüfen Sie bitte Ihren Spam-Ordner. Sie können sich im Problemfall auch gerne per E-Mail an vorstand1@sg-mettenberg.de oder per Telefon an 01774344755 wenden.</p>
          </div>
          </Container>
        </div>
      );
    } else {
      return (
        <div>
          <AppNavbarWithoutCart/>
          <Container fluid>
          <h3>SG Mettenberg e.V. Ticketverkauf Print@Home</h3>
            <div>
            <br></br>
            <img src={require('./SGMLogo.jpg')} width="83" height="98" id="logos"/>
            <p>Offizieller Ticketverkauf für das Jubiläumswochenende der SG Mettenberg.</p>
            <p>Der Bestellzeitraum ist leider abgelaufen.</p>
            <br></br>
            <b>SAUBACHKOME.DE</b>
            <ul>
              <li>Eintritt für den Auftritt der <a href="https://www.saubachkome.de/" target="_blank">SAUBACHKOME.DE</a> am <b>Freitag, 24. Januar 2025</b>, um 20:00 Uhr</li>
              <li>Mehrzweckhalle Mettenberg. Einlass ab 18:30 Uhr. Freie Platzwahl!</li>
            </ul>   
            <b>Buffet am Sonntag</b>
            <ul>
              <li>Mittagessen mit schwäbischem Buffet am <b>Sonntag, 26. Januar 2025</b>, um TODO Uhr</li>
              <li>Mehrzweckhalle Mettenberg. Einlass ab TODO Uhr. Freie Platzwahl!</li>
            </ul>     
            <br></br> 
            <p>Mehr Infos zur Veranstaltung finden Sie hier: <a href="https://www.sg-mettenberg.de/" target="_blank">https://www.sg-mettenberg.de/</a> </p>
          </div>
          </Container>
        </div>
          );
    }
  }
}

export default withRouter(Preisliste);